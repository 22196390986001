// ###general
@import url('https://fonts.googleapis.com/css?family=Poppins|Roboto&display=swap');

// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

div#website {
  position:relative; /* needed for footer positioning*/
  margin:0 auto; /* center, not in IE5 */
  height:auto !important; /* real browsers */
  height:100%; /* IE6: treaded as min-height*/
  min-height:100vh; /* real browsers */
}

div#header {
  padding:0.2em;
}

div#content {
  padding:1em 1em 5em; /* bottom padding for footer */
}

div#footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
    /* position: absolute;
    bottom: 0;
    /* line-height: 30px; /* Vertically center the text there */
}

//Wemint colors

// $body-bg: #f8f9fa;
// Old wemint : 579a0b
$primary: #00B48C;
$wemint: #00B48C;

.minitext
{
  font-size: 0.875rem;
}
.wemint
{
  color: #00B48C;
}
.mint
{
  color: #00B48C;
}
.usedbar
{
  height: 1px;
}

.center
{
  display: flex;
  justify-content: center;
}

.catchphrase
{
  font-family: 'Poppins', sans-serif;
}

.underpage
{ font-family: 'Roboto', sans-serif; }

.thin-1 {
  font-size: 6rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-2
{
  font-size: 5.5rem;
  //font-weight: 100;
  line-height: 1.2;
}

.thin-3 {
  font-size: 4.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-4 {
  font-size: 3.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-5 {
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.thin-6 {
  font-size: 1.5rem;
  font-weight: 170;
  line-height: 1.2;
}

.order-select {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem;
}

/* Custom default button */
.btn-order,
.btn-order:hover,
.btn-order:focus {
  color: #579a0b;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #579a0b;
  border: .05rem solid #579a0b;
}

.row.vdivide [class*='col-']:not(:last-child):after {
  background: #e0e0e0;
  width: 1px;
  content: "";
  display:block;
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  min-height: 70px;
}

/*#Import bootstrap*/
@import '~bootstrap/scss/bootstrap.scss';

/*#Import pretty checkbox*/
// @import '../node_modules/pretty-checkbox/src/pretty-checkbox.scss';

/*#Import Fork-Awesome*/
// @import '../node_modules/fork-awesome/scss/variables.scss'

/* 
* full website CSS from old the old "presentation" project.
*/

.link {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 3px;
    width: 0%;
    background-color: #999;
    border-radius: 2px;
    top: 120%;
    left: 50%;
    will-change: width;
    transition: all 100ms linear;
    transform: translate(-50%, -50%);
  }

  &:hover {
    text-decoration: none;
    &::before {
      width: 100%;
    }
  }
}
.active {
  &::before {
    width: 100%;
    background-color: $wemint;
  }
}

/* Part from old presentation.scss, should be cleaned soon */
.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }


/*.p-header,
.pt-header,
.py-header {
  padding-top: 1.5rem !important;
}
*/
.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }
  